import React from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'
import styl from './header.module.css'
import './menu.css'
import Logo from '../img/CL_logo.png'

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'unset', zIndex: '2' }}
    >
      {' '}
    </Sticky>
    <div id="header">
      <div id="main-nav" class="white-bg py-2">
        <div className="container">
          <div className="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <div class="logo-wrap">
                <img class="img-fluid" src={Logo} />
              </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
              <div class="nav_content">
                <SideBarMenu />
              </div>
              <div class="float-right align-self-center right-nav mt-2">
                <div class="float-right align-self-center right-nav mt-2">
                  <a
                    class="mr-3 mt-3 btn-outline-orange-home"
                    href="tel://7195402100"
                    aria-hidden="true"
                  >
                    <i class="fa fa-phone-square" />
                    <span>&nbsp;CALL TODAY</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default Header
