import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import MySwiper from '../components/Swiper'
import SampleForm from '../components/Form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import About from '../img/office-spaces.jpg'
import Pushpin from '../img/001-push-pin.png'
import Clip from '../img/002-attachment.png'
import Folder from '../img/003-folder.png'
import PMCN from '../img/PMCN.png'
import PCFV from '../img/proudlycaringforveterans-vertbadge-225x300.png'
import Carmel from '../img/mt-carmel.png'
import Colvet from '../img/colvet.png'
import GoogleMapReact from 'google-map-react'

const AnyReactComponent = ({ text }) => <div>{text}</div>

class IndexPage extends React.Component {
  static defaultProps = {
    center: {
      lat: 38.8399991,
      lng: -104.7909748,
    },
    zoom: 12,
  }

  createMapOptions = maps => {
    return {
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#ebe3cd',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#523735',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f1e6',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#c9b2a6',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#dcd2be',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#ae9e90',
            },
          ],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dfd2ae',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dfd2ae',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#93817c',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#a5b076',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#447530',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f1e6',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#fdfcf8',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f8c967',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#e9bc62',
            },
          ],
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e98d58',
            },
          ],
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#db8555',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#806b63',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dfd2ae',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#8f7d77',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ebe3cd',
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dfd2ae',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#b9d3c2',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#92998d',
            },
          ],
        },
      ],
    }
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    console.log(this.createMapOptions)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div id="vegas" class="slider-bg section">
          <div class="container">
            <div class="slider-dec align-items-center">
              <div id="slider-caption">
                <h1 class="text-gradient text-uppercase atf">
                  CIRCLE HOLDINGS
                </h1>
                <h4 class="heading atf">
                  Solid Infrastructure to enable seamless operations
                  <br />
                  <br />
                </h4>
              </div>
              <div id="mt-5" class="mt-3 btn-outline-orange-home">
                <a
                  class="mt-3 btn-outline-orange-home atf"
                  href="#what-we-offer"
                >
                  SERVICES OFFERED
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="about-us" class="home-aboutus">
          <div class="container">
            <div class="row about-us">
              <div class="col-sm-12 col-md-6">
                <h4 class="text-orange text-uppercase">ABOUT US</h4>
                <h1 class="text-uppercase about-us">
                  PRIVATE OFFICES &amp; BUSINESS SPACE
                </h1>
              </div>
              <div class="col-sm-12 col-md-6">
                <p class="about-us p">
                  It seems like a commercial real estate agent is a dime a dozen
                  these days. We know when you are looking to partner with a
                  firm there are a handful of important pieces that must fit in
                  together to make the puzzle work.Over the last seventeen
                  years, we have honed our trade and understand the practices of
                  our industry. These core foundational business practices have
                  catapulted Office Space Brokers to the top our industry in our
                  markets. We want the opportunity to share with you why we are
                  so deeply committed to our clients and whether locally owned
                  to national firms we protect the rights and bottom line for
                  everyone from business owners to nonprofits.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="what-we-offer" class="showcase">
          <div class="container">
            <div class="row mb-0">
              <div class="col s12 m12 l6 xl6 px-0 showcase-left">
                <div class="content">
                  <img class="showcase img" src={About} />
                </div>
              </div>
              <div class="col s12 m12 l6 xl6 showcase-right">
                <div class="p-3">
                  <h4 class="text-orange text-uppercase">WHAT WE OFFER</h4>
                  <h1 class="text-uppercase h">
                    SOLID INFRASTRUCTURE TO ENABLE SEAMLESS OPERATIONS
                  </h1>
                  <div class="justify mb-3">
                    <ul class="justify mb-3">
                      <li class="mb-3">
                        <span class="title-bold">
                          Fast and Reliable Internet Connection&nbsp;
                        </span>
                        <span>
                          <br />
                          With 70+ servers &amp; 3GBps Fiber Internet, we
                          provide you with a robust networking infrastructure
                          and access to Wi-Fi in all locations.
                        </span>
                      </li>
                      <li class="mb-3">
                        <span class="title-bold">
                          Modern Plug-and-Play Spaces
                        </span>
                        <span class="mb-3">
                          <br />
                          All our spaces include stylish&nbsp;interior&nbsp;and
                          common areas such as bars, multi-function rooms and
                          pantries.
                        </span>
                      </li>
                      <li class="mb-3">
                        <span class="title-bold">
                          24/7 Utilities and Support services
                        </span>
                        <span>
                          <br />
                          to ensure seamless operations and year-round support.
                        </span>
                      </li>
                      <li class="mb-3" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="services" class="mt-5">
          <div class="container">
            <div class="row text-white">
              <div class="col-lg-4 col-md-6 col-sm-12 text-center mb-4 blinds">
                <div class="service-img-bg">
                  <img class="blinds-icon" src={Pushpin} />
                  <h4 class="text-uppercase h-blinds">
                    PRIVATE OFFICES &amp; COWORKING
                  </h4>
                  <p class="p center">
                    An open plan with unconventional design features is planned
                    for the common areas for the enjoyment of workers
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 text-center mb-4 blinds center">
                <div class="service-img-bg">
                  <img class="blinds-icon" src={Clip} />
                  <h4 class="text-uppercase h-blinds">
                    COMMUNITY &amp; <br />
                    EVENT SPACES
                  </h4>
                  <p class="p center">
                    Supported by a vibrant ground level retail strip and a 500
                    sqm. Interactive food court which cater to the needs of
                    young and active workers
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 text-center mb-4 blinds left">
                <div class="service-img-bg">
                  <img class="blinds-icon" src={Folder} />
                  <h4 class="text-uppercase h-blinds">
                    MODERN &amp;
                    <br />
                    SUSTAINABLE&nbsp;DESIGN
                  </h4>
                  <p class="p center">
                    Uses environmentally sustainable design through energy
                    efficient electrical, air-conditioning and plumbing systems
                    which also works to reduce operational costs for
                    locators&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center pb-5">
          <div id="contact-us" class="container py-5">
            <div class="contact-clean col-md-6 col-sm-12">
              <SampleForm
                id="form"
                form_name="Newsletter Form"
                form_id="5bcd42f86b63453b251972bc"
                form_classname="form-newsletter"
              >
                <h2 class="text-center form text-uppercase h-blinds">
                  Contact us
                </h2>
                <h2 class="text-center text-orange-form">
                  For Inquires and updates
                </h2>
                <div className="form-row">
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        name="Email Address"
                        id="email_address"
                      />
                      <label htmlFor="email_address">
                        Enter Your Email Address
                      </label>
                    </div>
                    <small className="text-dark form-text mt-2">
                      We will never share your email with anyone else.
                    </small>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="Name"
                        id="full_name"
                      />
                      <label htmlFor="full_name">Enter Your Name</label>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        type="text"
                        name="Message"
                        id="message"
                      />
                      <label htmlFor="full_name">MESSAGE</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="webriq-recaptcha" />
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <button
                        className="mt-3 btn-outline-orange-home form"
                        type="submit"
                      >
                        SEND
                      </button>
                    </div>
                  </div>
                </div>
              </SampleForm>
            </div>
            <div class="col-md-6 col-sm-12" />
          </div>
        </div>
        <div class="Contact row m-0">
          <div id="map" class="col-md-6">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCEfylSsr-psnFLOIngLrWdKo2GgHo4488',
              }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              options={this.createMapOptions}
            >
              <AnyReactComponent
                lat={38.789578}
                lng={-104.803233}
                text={
                  <div class="address">
                    <div class="map-box">
                      <h6>
                        Family Care Center - South
                        <br />
                        1330 Quail Lake Loop, Ste 260, COS, CO 80906
                      </h6>
                    </div>
                  </div>
                }
              />
              <AnyReactComponent
                lat={38.876674}
                lng={-104.758469}
                text={
                  <div class="address">
                    <div class="map-box">
                      <h6>
                        Family Care Center - Central
                        <br />
                        3715 Parkmoor Village Dr Ste 102, COS, CO 80917
                      </h6>
                    </div>
                  </div>
                }
              />
            </GoogleMapReact>
          </div>
          <div
            class="col-md-6 col-sm-12 gray-wrapper gray-bg text-gray pb-5"
            id="contact"
          >
            <a class="btn-outline-orange-contact mt-4 m-3" href="#">
              <span class="fa fa-handshake-o fa-lg mr-3">&nbsp;</span>PARTNERED
              WITH
            </a>
            <div class="row badge-wrap text-center pl-3">
              <div class="col-md-4 p-3">
                <h5 class="text-white font-weight-bold">
                  <strong>Colorado Veterans Health and Wellness Agency</strong>
                </h5>
                <a href="#">
                  <img class="CVHWA img-fluid" src={Colvet} />
                </a>
              </div>
              <div class="col-md-4 p-3">
                <h5 class="text-white font-weight-bold">
                  <strong>Peak Military Care Network</strong>
                  <br />
                </h5>
                <a href="https://pmcn.org/" target="_blank">
                  <img class="img-fluid" src={PMCN} />
                </a>
              </div>
              <div class="col-md-4 p-3">
                <h5 class="text-white font-weight-bold">
                  <strong>Peak Military Care Network</strong>
                  <br />
                </h5>
                <a
                  href="https://www.va.gov/COMMUNITYCARE/programs/veterans/VCP/index.asp"
                  target="_blank"
                  src="http://www.triwest.com/globalassets/vapc3-provider-files/resources/proudlycaringforveterans-vertbadge-225x300.png"
                >
                  <img class="PMCN img-fluid" src={PCFV} />
                </a>
              </div>
              <div class="col-md-4 p-3">
                <h5 class="text-white font-weight-bold">
                  <strong>Mt. Carmel Veterans Service Center</strong>
                  <br />
                </h5>
                <a
                  href="https://veteranscenter.org/"
                  target="_blank"
                  src="http://www.triwest.com/globalassets/vapc3-provider-files/resources/proudlycaringforveterans-vertbadge-225x300.png"
                >
                  <img class="img-fluid" src={Carmel} />
                </a>
              </div>
            </div>
            <div />
            <div class="p-3">
              <a class="btn-outline-orange-contact" href="#">
                <span class="fa fa-users fa-lg mr-3">&nbsp;</span>CAREERS
              </a>
              <h1 class="text-white font-weight-bold mt-5">Contact Details</h1>
              <ul class="list-unstyled">
                <li class="map-marker" aria-hidden="true">
                  <span class="text-white">
                    <span class="text-white font-weight-bold d-block">
                      <strong>Family Care Center - South</strong>
                      <br />
                    </span>
                    <span>
                      1330 Quail Lake Loop, Ste 260 <br />
                      COS, CO 80906
                    </span>
                  </span>
                  <i class="fa fa-star" />
                </li>
                <li class="divider" aria-hidden="true">
                  <div class="separator mb-3" />
                  <span class="text-white">
                    <span class="text-white font-weight-bold d-block">
                      <strong>Family Care Center - Central</strong>
                      <br />
                    </span>
                    <span>
                      3715 Parkmoor Village Dr., Ste 102
                      <br />
                      COS, CO 80917
                      <br />
                      <br />
                    </span>
                  </span>
                </li>
                <li class="phone" aria-hidden="true">
                  <span class="font-weight-bold m-0 pt-2">
                    <span class="text-white">
                      <strong>Phone:</strong>
                      <a class="text-white ml-2" href="tel://7195402100">
                        <strong>719-540-2100</strong>
                      </a>
                    </span>
                    <span class="text-white">
                      <strong>&nbsp; Fax:</strong>
                      <a class="text-white ml-2" href="tel://7195402102">
                        <strong>719-540-2102</strong>
                      </a>
                    </span>
                  </span>
                </li>
                <li class="mail">
                  <span class="font-weight-bold m-0 pt-2">
                    <a
                      class="text-white font-weight-bold m-0 mx-2 pt-2 d-inline-block"
                      href="mail:fccinfo@fccsprings.com"
                      target="_blank"
                    >
                      <strong>FCC Information</strong>
                    </a>
                    <a
                      class="text-white font-weight-bold m-0 mx-2 pt-2 d-inline-block"
                      href="mailto:tmsintake@fccsprings.com"
                      target="_blank"
                    >
                      <strong>TMS Information</strong>
                    </a>
                    <a
                      class="text-white font-weight-bold m-0 mx-2 pt-2 d-inline-block"
                      href="mailto:fccbilling@fccsprings.com"
                      target="_blank"
                    >
                      <strong>Billing</strong>
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
