import React from 'react'
import { Link } from 'gatsby'
import { Nav, Dropdown, NavItem } from 'react-bootstrap'
import './menu.css'
import Logo from '../img/CL_logo.png'

export default () => (
  <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
    <div class="nav-brand">
      <img class="img-fluid" src={Logo} />
    </div>
    <div class="nav_content">
      <Nav className="flex-column list-unstyled d-flex main-nav mb-0">
        <Nav.Item>
          <Link class="navlink" to="/">
            Home
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link class="navlink" to="/#about-us">
            About Us
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link class="navlink" to="/#what-we-offer">
            Services
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link class="navlink" to="/#contact-us">
            Contact Us
          </Link>
        </Nav.Item>
        {/*<Dropdown as={NavItem}>
        <Dropdown.Toggle as={Nav.Link}>Dropdown</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>Hello there!</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={NavItem}>
        <Link variant="success" className="float-left" to="/">
          Dropdown Split Button
        </Link>
        <Dropdown.Toggle split id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Dropdown.Item hred="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item hred="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item hred="#/action-3">Something else</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>*/}
      </Nav>
    </div>
  </div>
)
