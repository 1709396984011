import React from 'react'
import { Link } from 'gatsby'
import { rhythm, scale } from '../utils/typography'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './Header'
import './styles.css'
import './index.css'
import './Blinds.css'
import './menu.css'
import './bootstrap.min.css'
import './updated-side-menu.css'
import './updated-side-menu-1.css'
import './Contact-Form-Clean.css'
import 'font-awesome/css/font-awesome.css'
import GoogleMapReact from 'google-map-react'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div>
        <Header />
        <div>
          {children}
          <footer>
            <div id="footer-bottom" class="orange-bg">
              <div class="container">
                <div class="row text-white py-4 footer">
                  <div class="col-md-6 col-sm-12">
                    <span class="spanYear">
                      © Copyright 2019&nbsp; | Family Care Center, LLC
                      <br />
                    </span>
                  </div>
                  <div class="col-md-6 col-sm-12 text-right">
                    <span class="spanYear">Designed and Powered by </span>
                    <a
                      href="http://webriq.com"
                      target="_blank"
                      alt="WebriQ"
                      rel="nofollow"
                    >
                      WebriQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default Layout
